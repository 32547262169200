import React from "react"
import styles from "./styles.module.less"

const NikeHoliday = () => {
  return (
    <div className={styles.NikeHoliday2020}>
      <h1>NIKE</h1>
    </div>
  )
}

export default NikeHoliday
